import React from 'react';
import '../style/footer.scss';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="line"></div>
      <div className="footer-content">
        <div className="contact-info">
          <h1>Contact Us</h1>
          <p>+ 381 62 223 980</p>
          <p>+ 381 60 400 97 90</p>
          <p>binis.apartmani.zlatibor@gmail.com</p>
          <p>Erska 29, 31315 Zlatibor</p>
          <div className="social-icons">
            <a href="https://facebook.com/p/Apart-kompleks-BINIS-61558442823571" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={30} />
            </a>
            <a href="https://instagram.com/apartkompleksbinis" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={30} />
            </a>
          </div>
        </div>
        <div className="google-map">
          {/* Placeholder for Google Map iframe */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.530934678311!2d19.65386831540831!3d43.67765839514509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4758c79698f50c77%3A0xe62b7b54456c0d78!2sErska%2029%2C%2031315%20Zlatibor%2C%20Serbia!5e0!3m2!1sen!2srs!4v1699909269477!5m2!1sen!2srs"
            width="100%"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
