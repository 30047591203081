import React from 'react';
import { useState, useEffect } from 'react';
import '../style/accomodation.scss';

const Accomodation = () => {
  const [slideIndex1, setSlideIndex1] = useState(0);
  const [slideIndex2, setSlideIndex2] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [language, setLanguage] = useState('SRB'); // Default language is Serbian

  const images1 = [
    "/assets/collage1.jpg",
    "/assets/collage2.jpg",
    "/assets/collage3.jpg",
    "/assets/collage4.jpg",
  ];

  const images2 = [
    "/assets/collage5.jpg",
    "/assets/collage6.jpg",
    "/assets/collage7.jpg",
    "/assets/collage8.jpg",
  ];

  useEffect(() => {
    const interval1 = setInterval(() => {
      setSlideIndex1((prevIndex) => (prevIndex + 1) % images1.length);
    }, 3000);

    const interval2 = setInterval(() => {
      setSlideIndex2((prevIndex) => (prevIndex + 1) % images2.length);
    }, 3000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, [images1.length, images2.length]);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode);
    document.body.classList.toggle('dark-mode', savedDarkMode);
    document.body.classList.toggle('light-mode', !savedDarkMode);

    const handleDarkModeToggle = () => {
      const isDarkMode = localStorage.getItem('darkMode') === 'true';
      setIsDarkMode(isDarkMode);
      document.body.classList.toggle('dark-mode', isDarkMode);
      document.body.classList.toggle('light-mode', !isDarkMode);
    };

    window.addEventListener('darkModeToggled', handleDarkModeToggle);

    return () => {
      window.removeEventListener('darkModeToggled', handleDarkModeToggle);
    };
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'SRB';
    setLanguage(savedLanguage);

    const handleLanguageChange = () => {
      const currentLanguage = localStorage.getItem('language') || 'SRB';
      setLanguage(currentLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
      <section id="accommodation" className={`accommodation ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <div className="accommodation-container">
          <div className="left-section">
            <h1 className="main-title">
              {language === 'SRB' ? 'SMEŠTAJ' : 'ACCOMMODATION'} <span className="arrow">→</span>
            </h1>
            <h1 className="sub-title">
              {language === 'SRB' ? 'Najbolja soba baš za Vas!' : 'The best room just for you!'}
            </h1>
            <p className="description">
              {language === 'SRB'
                  ? 'Naš kompleks raspolaže sa 30 luksuznih smeštajnih jedinica od 40m², osmišljenih da obezbede maksimalan komfor i opremljenih u skladu sa najvišim standardima četvorozvezdanog smeštaja. Svaki apartman se sastoji od udobne spavaće sobe, prostranog dnevnog boravka sa rasklopivim ležajem, potpuno opremljene kuhinje, modernog kupatila i prijatne terase. Apartmani su opremljeni ravnim TV ekranima i brzim Wi-Fi internetom. Većina apartmana ima svoj privatni garažni prostor, a gostima su na raspolaganju i dodatna otvorena parking mesta.'
                  : 'Our complex features 30 luxurious accommodation units of 40m², designed to provide maximum comfort and equipped to the highest 4-star standards. Each apartment comprises a cozy bedroom, a spacious living room with a fold-out bed, a fully equipped kitchen, a modern bathroom, and a pleasant terrace. The apartments are equipped with flat-screen TVs and high-speed Wi-Fi. Most of the apartments have their own private garage space, and guests also have access to additional outdoor parking spaces.'}
            </p>
          </div>

          <div className="right-section">
            {/* Slideshow 1 */}
            <div className="acomslideshow">
              {images1.map((img, index) => (
                  <img
                      key={index}
                      src={img}
                      alt={`Slideshow 1 - ${index}`}
                      className={`slide ${index === slideIndex1 ? 'active' : ''}`}
                  />
              ))}
            </div>

            {/* Slideshow 2 */}
            <div className="acomslideshow">
              {images2.map((img, index) => (
                  <img
                      key={index}
                      src={img}
                      alt={`Slideshow 2 - ${index}`}
                      className={`slide ${index === slideIndex2 ? 'active' : ''}`}
                  />
              ))}
            </div>
          </div>
        </div>
      </section>
  );
};

export default Accomodation;