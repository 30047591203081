import React from 'react';
import { useState, useEffect } from 'react';
import '../style/restaurant.scss';

const Restaurant = () => {
  const [slideIndex1, setSlideIndex1] = useState(0);
  const [slideIndex2, setSlideIndex2] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [language, setLanguage] = useState('SRB'); // Default language is Serbian

  const images1 = [
    "/assets/restaurant1.jpg",
    "/assets/restaurant2.jpg",
    "/assets/restaurant3.jpg",
    "/assets/restaurant4.jpg",
  ];

  const images2 = [
    "/assets/restaurant5.jpg",
    "/assets/restaurant1.jpg",
    "/assets/restaurant2.jpg",
    "/assets/restaurant3.jpg",
  ];

  useEffect(() => {
    const interval1 = setInterval(() => {
      setSlideIndex1((prevIndex) => (prevIndex + 1) % images1.length);
    }, 3000);

    const interval2 = setInterval(() => {
      setSlideIndex2((prevIndex) => (prevIndex + 1) % images2.length);
    }, 3000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, [images1.length, images2.length]);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode);
    document.body.classList.toggle('dark-mode', savedDarkMode);
    document.body.classList.toggle('light-mode', !savedDarkMode);

    const handleDarkModeToggle = () => {
      const isDarkMode = localStorage.getItem('darkMode') === 'true';
      setIsDarkMode(isDarkMode);
      document.body.classList.toggle('dark-mode', isDarkMode);
      document.body.classList.toggle('light-mode', !isDarkMode);
    };

    window.addEventListener('darkModeToggled', handleDarkModeToggle);

    return () => {
      window.removeEventListener('darkModeToggled', handleDarkModeToggle);
    };
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'SRB';
    setLanguage(savedLanguage);

    const handleLanguageChange = () => {
      const currentLanguage = localStorage.getItem('language') || 'SRB';
      setLanguage(currentLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
      <section id="restaurant" className={`restaurant ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <div className="restaurant-container">
          <div className="left-section">
            <div className="slideshow">
              {images1.map((img, index) => (
                  <img
                      key={index}
                      src={img}
                      alt={`Slideshow 1 - ${index}`}
                      className={`slide ${index === slideIndex1 ? 'active' : ''}`}
                  />
              ))}
            </div>
            <div className="slideshow">
              {images2.map((img, index) => (
                  <img
                      key={index}
                      src={img}
                      alt={`Slideshow 2 - ${index}`}
                      className={`slide ${index === slideIndex2 ? 'active' : ''}`}
                  />
              ))}
            </div>
          </div>

          <div className="right-section">
            <div className="title-with-restarrow">
              <span className="restarrow">&larr;</span>
              <h1 className="main-title">{language === 'SRB' ? 'RESTORAN' : 'RESTAURANT'}</h1>
            </div>
            <p className="description">
              {language === 'SRB'
                  ? 'U srcu našeg kompleksa nalazi se Restoran Binis, poznat po jedinstvenoj ponudi vrhunskih jela i porodičnoj atmosferi. Gosti mogu uživati u tradicionalnim i internacionalnim specijalitetima. Ono što izdvaja restoran Binis jeste ne samo ukusna hrana, već i prostor koji pruža neizmernu radost deci. Naša igraonica je mesto gde mališani mogu istraživati, stvarati i uživati, dok roditelji opušteno uživaju u obroku i razgovoru. Ambijent restorana je savršen za nezaboravne obroke i zajedničke trenutke, bilo da dolazite u paru ili sa porodicom.'
                  : 'At the heart of our complex lies Restaurant Binis, renowned for its unique offering of exquisite dishes and family-friendly atmosphere. Guests can indulge in traditional and international specialties. What sets Restaurant Binis apart is not only the delicious food but also the space that brings immense joy to children. Our playroom is a place where young ones can explore, create, and enjoy while parents relax and savor their meals and conversations. The restaurant’s ambiance is perfect for unforgettable meals and shared moments, whether you are visiting as a couple or with family.'}
            </p>
          </div>
        </div>
      </section>
  );
};

export default Restaurant;