import React, { useState, useEffect } from 'react';
import '../style/hero.scss';

const Hero = () => {
  const [language, setLanguage] = useState('SRB'); // Default language is Serbian

  useEffect(() => {
    // Load language setting
    const savedLanguage = localStorage.getItem('language') || 'SRB';
    setLanguage(savedLanguage);

    // Event listener for the custom event
    const handleLanguageChange = () => {
      const currentLanguage = localStorage.getItem('language') || 'SRB';
      setLanguage(currentLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
      <section className="hero">
        <div className="hero-image">
          <img src="/assets/herocover.jpg" alt="Hero" />
          <div className="left-section">
            <p>{language === 'SRB' ? 'Dobrodošli u ' : 'Welcome to '}<br /> Binis Complex</p>
          </div>
          <div className="right-section">
            <div className="blue-div">
              <div className="image-container">
                <img src="/assets/heroimage1.png" alt="Small" />
              </div>
              <p className="description">
                {language === 'SRB'
                    ? 'Savršeno mesto za porodice, pogodno za decu, gde možete uživati u najvišem kvalitetu opuštanja.'
                    : 'A perfect place for families, suitable for children, where you can enjoy the highest quality of relaxation.'}
              </p>
              <a href="#accommodation" className="explore-link">
                {language === 'SRB' ? 'Istražite naše sobe' : 'Explore our rooms'} <span className="heroarrow">→</span>
              </a>
            </div>
            <div className="bottom-half">
              <div className="image-container">
                <img src="/assets/heroimage2.png" alt="Small"/>
              </div>
              <p className="description">
                {language === 'SRB'
                    ? 'Uživajte u opuštajućoj atmosferi sa svojim najdražima uz vrhunsku uslugu i bogat izbor domaćih i internacionalnih specijaliteta.'
                    : 'Enjoy a relaxing atmosphere with your loved ones, accompanied by excellent service and a wide selection of local and international specialties.'}
              </p>
              <a href="#restaurant" className="explore-link">
                {language === 'SRB' ? 'Istražite naš restoran' : 'Explore our restaurant'} <span className="heroarrow">→</span>
              </a>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Hero;