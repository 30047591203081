import React, { useState, useEffect } from 'react';
import '../style/wellnesscenter.scss';

const WellnessCenter = () => {
  const [slideIndex1, setSlideIndex1] = useState(0);
  const [slideIndex2, setSlideIndex2] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [language, setLanguage] = useState('SRB'); // Default language is Serbian

  const images1 = [
    "/assets/spa1.jpg",
    "/assets/spa2.jpg",
    "/assets/spa3.jpg",
    "/assets/spa4.jpg",
  ];

  const images2 = [
    "/assets/spa5.jpg",
    "/assets/spa6.jpg",
    "/assets/spa7.jpg",
    "/assets/spa8.jpg",
  ];

  useEffect(() => {
    const interval1 = setInterval(() => {
      setSlideIndex1((prevIndex) => (prevIndex + 1) % images1.length);
    }, 3000);

    const interval2 = setInterval(() => {
      setSlideIndex2((prevIndex) => (prevIndex + 1) % images2.length);
    }, 3000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, [images1.length, images2.length]);

  // Read initial mode from localStorage or default to light mode
  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode);
    document.body.classList.toggle('dark-mode', savedDarkMode);
    document.body.classList.toggle('light-mode', !savedDarkMode);

    // Add an event listener for the custom event
    const handleDarkModeToggle = () => {
      const isDarkMode = localStorage.getItem('darkMode') === 'true';
      setIsDarkMode(isDarkMode);
      document.body.classList.toggle('dark-mode', isDarkMode);
      document.body.classList.toggle('light-mode', !isDarkMode);
    };

    window.addEventListener('darkModeToggled', handleDarkModeToggle);

    return () => {
      window.removeEventListener('darkModeToggled', handleDarkModeToggle);
    };
  }, []);

  // Read initial language from localStorage or default to SRB (Serbian)
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'SRB'; // Default language is Serbian
    setLanguage(savedLanguage);

    // Add an event listener for the custom event
    const handleLanguageChange = () => {
      const currentLanguage = localStorage.getItem('language') || 'SRB'; // Default language is Serbian
      setLanguage(currentLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
      <section className={`wellness-center ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <div className="wellness-container">
          <div className="left-section">
            <div className="title-container">
              <h1 className="main-title">{language === 'SRB' ? 'Spa i Wellness Centar' : 'Spa and Wellness Center'}</h1>
              <span className="arrow">→</span>
            </div>
            <p className="description">
              {language === 'SRB'
                  ? 'Nudimo savršeno mesto za relaksaciju, uz hidromasažnu kadu za 12 osoba, saunu, parno kupatilo, tepidarijum, i dve sobe za masaže. Našim gostima su na raspolaganju profesionalne masaže i širok izbor tretmana tela koristeći visokokvalitetnu kozmetiku za dodatno opuštanje i revitalizaciju. Obezbedite sebi vrhunsko spa iskustvo u mirnom okruženju Zlatibora, gde se možete osloboditi stresa i napetosti svakodnevnog života. Sa pažljivom negom našeg stručnog osoblja, sigurni smo da ćete obnoviti svoj duh i telo.'
                  : 'We offer the perfect place for relaxation, featuring a jacuzzi for 12 people, sauna, steam bath, tepidarium, and two massage rooms. Guests have access to professional massages and a wide range of body treatments using high-quality cosmetics for additional relaxation and revitalization. Ensure yourself a top-notch spa experience in the serene environment of Zlatibor, where you can relieve the stress and tension of everyday life. With the attentive care of our expert staff, we are confident you will rejuvenate both your spirit and body.'}
            </p>
          </div>

          <div className="right-section">
            <div className="wellslideshow">
              {images1.map((src, index) => (
                  <img
                      key={index}
                      src={src}
                      alt={`Slide 1 - ${index}`}
                      className={`slide ${index === slideIndex1 ? 'active' : ''}`}
                  />
              ))}
            </div>
            <div className="wellslideshow">
              {images2.map((src, index) => (
                  <img
                      key={index}
                      src={src}
                      alt={`Slide 2 - ${index}`}
                      className={`slide ${index === slideIndex2 ? 'active' : ''}`}
                  />
              ))}
            </div>
          </div>
        </div>
      </section>
  );
};

export default WellnessCenter;