import React from 'react';
import Navbar from './main/Navbar';
import Hero from './section/Hero';
import Accomodation from './section/Accomodation';
import Restaurant from './section/Restaurant';
import Playroom from './section/Playroom';
import Wellnesscenter from './section/Wellnesscenter';
import Footer from './main/Footer';

const Landingpage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Accomodation />
      <Restaurant />
      <Playroom />
      <Wellnesscenter />
      <Footer />
    </div>
  );
};

export default Landingpage;
