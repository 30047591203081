import React, { useState, useEffect } from 'react';
import '../style/navbar.scss';
import { IoMdSunny, IoMdMoon } from 'react-icons/io'; // Import icons from react-icons

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [preferDarkMode, setPreferDarkMode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);
    const [language, setLanguage] = useState('SRB'); // Default language is Serbian

    useEffect(() => {
        // Load dark mode setting or user preference
        const savedDarkMode = localStorage.getItem('darkMode');
        const prefersDarkMode = savedDarkMode !== null ? savedDarkMode === 'true' : preferDarkMode;

        setIsDarkMode(prefersDarkMode);
        document.body.classList.toggle('dark-mode', prefersDarkMode);
        document.body.classList.toggle('light-mode', !prefersDarkMode);

        // Add event listener to handle changes in the user's preferred color scheme
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleMediaChange = (event) => {
            setPreferDarkMode(event.matches);
            if (savedDarkMode === null) {  // Only update if there's no saved preference
                setIsDarkMode(event.matches);
                document.body.classList.toggle('dark-mode', event.matches);
                document.body.classList.toggle('light-mode', !event.matches);
            }
        };

        mediaQuery.addEventListener('change', handleMediaChange);

        // Load language setting
        const savedLanguage = localStorage.getItem('language') || 'SRB';
        setLanguage(savedLanguage);

        // Log initial settings
        console.log('Initial mode:', prefersDarkMode ? 'Dark' : 'Light');
        console.log('Initial language:', savedLanguage);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaChange);
        };
    }, [preferDarkMode]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const toggleDarkMode = () => {
        const newMode = !isDarkMode;
        setIsDarkMode(newMode);
        document.body.classList.toggle('dark-mode', newMode);
        document.body.classList.toggle('light-mode', !newMode);
        localStorage.setItem('darkMode', newMode.toString());
        console.log(`Switched to ${newMode ? 'Dark Mode' : 'Light Mode'}`);

        // Dispatch a custom event when dark mode is toggled
        const event = new Event('darkModeToggled');
        window.dispatchEvent(event);
    };

    const changeLanguage = (e) => {
        const newLanguage = e.target.value;
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
        console.log('Language changed to', newLanguage);
        // Dispatch a custom event when language is changed
        const event = new Event('languageChanged');
        window.dispatchEvent(event);
    };

    return (
        <nav className={`navbar ${isDarkMode ? 'dark' : 'light'}`}>
            <div className="navbar-logo">
                <img src="/logo192.png" alt="Logo" className="logo-image" />
                <span className="logo-text">Binis Complex</span>
            </div>
            <div className="navbar-hamburger" onClick={toggleMenu}>
                <span className="hamburger-line"></span>
            </div>
            <div className={`navbar-menu-wrapper ${isMenuOpen ? 'open' : ''}`}>
                <div className="navbar-menu">
                    <ul className="navbar-categories">
                        <li><a href="#accommodation"
                               onClick={closeMenu}>{language === 'SRB' ? 'SMEŠTAJ' : 'ACCOMMODATION'}</a></li>
                        <li><a href="#restaurant"
                               onClick={closeMenu}>{language === 'SRB' ? 'RESTORAN' : 'RESTAURANT'}</a></li>
                        <li><a href="#playroom"
                               onClick={closeMenu}>{language === 'SRB' ? 'IGRAONICA' : 'PLAYGROUND'}</a></li>
                    </ul>
                </div>
                <div className="navbar-language">
                    <a href="#footer" onClick={closeMenu} className="contact-button">
                        {language === 'SRB' ? 'Kontaktirajte Nas' : 'Contact Us'}
                    </a>
                    <select value={language} onChange={changeLanguage}>
                        <option value="EN">EN</option>
                        <option value="SRB">SRB</option>
                    </select>
                    <button onClick={toggleDarkMode} className="dark-mode-toggle">
                        {isDarkMode ? <IoMdSunny/> : <IoMdMoon/>}
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;